import '../../../styles/share.scss'

import PropTypes from 'prop-types'
import React from 'react'

import HelpPopover from './HelpPopover'
import {Overlay} from '../form'

function PopupHelper({open, onClose, popupType}) {
    return (
        <>
            <HelpPopover open={open || false} onClose={onClose}
                         popupType={popupType}/>
            <Overlay open={open || false}/>
        </>
    )
}

PopupHelper.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    popupType: PropTypes.string.isRequired
}

export default PopupHelper