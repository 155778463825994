import PropTypes from 'prop-types'
import React from 'react'

import { Radio } from '../form'

const FieldRadio = ({ id, text, value, onChange, disabled, options, hidden }) => (
  <div className={`control control-inline ${hidden ? "hidden" : ""}`}>
    <label htmlFor={id}>{text}</label>
    <Radio
      name='packaging'
      disabled={disabled}
      selected={value}
      options={options}
      onChange={onChange}
    />
  </div>
)

FieldRadio.defaultProps = {
  disabled: false,
  options: [],
  hidden: false,
}

FieldRadio.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  hidden: PropTypes.bool,
}

export default FieldRadio
