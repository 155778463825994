import PropTypes from 'prop-types'
import get from 'lodash.get'
import React, {useContext} from 'react'

import Actions from './Actions'
import Control from './Control'
import FieldError from './FieldError'
import FieldInput from './FieldInput'
import FieldRadio from './FieldRadio'
import Warnings from './Warnings'
import useWindowsUtils from '../../utils/WindowsUtils'
import {AppContext} from '../../reducer/App'
import {Button, Checkbox, Radio} from '../form'
import {Dependency} from '../dependency'
import {InitializrContext} from '../../reducer/Initializr'

const Fields = ({
                    onSubmit,
                    onExplore,
                    onShare,
                    onPopupHelp,
                    refExplore,
                    refSubmit,
                    refDependency,
                    generating,
                }) => {
    const windowsUtils = useWindowsUtils()
    const {config, dispatch, dependencies} = useContext(AppContext)
    const {values, dispatch: dispatchInitializr, errors} = useContext(
        InitializrContext
    )
    const update = args => {
        dispatchInitializr({type: 'UPDATE', payload: args})
    }

    function getParam(name) {
        if (name = (new RegExp('[?&]' + encodeURIComponent(name) + '=([^&]*)')).exec(location.search))
            return decodeURIComponent(name[1]);
    }

    const isBlcDeveloper = (getParam('blc-developer') === 'true');
    const isTelcoDeveloper = (getParam('telco') === 'true');

    let releaseTrainOptions = get(config, 'lists.releaseTrain')
    let demoExtensionOptions = get(config, 'lists.demoExtensions')

    // BLC Developer -> show all configuration options
    // Telco Developer -> only show Telco Release Train and Telco Demo
    // Everybody Else -> only show Stable Release Train and Heat Clinic Demo

    if (isTelcoDeveloper) {
        // only showcase the telco release train option
        releaseTrainOptions = get(config, 'lists.releaseTrain').filter(x => x.text.startsWith('telco'))
        // only showcase the "telco" demo extension
        demoExtensionOptions = get(config, 'lists.demoExtensions').filter(x => x.key === 'telco')
    } else if (!isBlcDeveloper) {
        // only showcase the first release train option (i.e. stable)
        releaseTrainOptions = get(config, 'lists.releaseTrain').filter(x => x.text.startsWith('stable'))
        // only showcase the "heatclinic" extension
        demoExtensionOptions = get(config, 'lists.demoExtensions').filter(x => x.key === 'heatclinic')
    }

    return (
        <>
            <div className='colset colset-main'>
                <div className='left'>
                    <Warnings/>
                    <div className='col-sticky'>
                        <div className='colset' style={{display: 'none'}}>
                            <div className='left'>
                                <Control text='Project'>
                                    <Radio
                                        name='project'
                                        selected={get(values, 'project')}
                                        options={get(config, 'lists.project')}
                                        onChange={value => {
                                            update({project: value})
                                        }}
                                    />
                                </Control>
                            </div>
                            <div className='right'>
                                <Control text='Language'>
                                    <Radio
                                        name='language'
                                        selected={get(values, 'language')}
                                        options={get(config, 'lists.language')}
                                        onChange={value => {
                                            update({language: value})
                                        }}
                                    />
                                </Control>
                            </div>
                        </div>

                        <Control text='Broadleaf Release Train'>
                            <Radio
                                name='releaseTrain'
                                selected={get(values, 'releaseTrain')}
                                error={get(errors, 'releaseTrain.value', '')}
                                options={releaseTrainOptions}
                                onChange={value => {
                                    dispatchInitializr({
                                        type: 'UPDATE',
                                        payload: {releaseTrain: value},
                                        config: get(dependencies, 'list'),
                                    })
                                    dispatch({
                                        type: 'UPDATE_DEPENDENCIES',
                                        payload: {releaseTrain: value},
                                    })
                                }}
                            />
                            {get(errors, 'releaseTrain') && (
                                <FieldError>
                                    Broadleaf Release Train {get(errors, 'releaseTrain.value')} is
                                    not supported.
                                    Please select a valid version.
                                </FieldError>
                            )}
                        </Control>

                        <Control text='Broadleaf Flex Package'
                                 onPopupHelp={() => onPopupHelp("flex-packages")}>
                            <Radio
                                name='flexPackage'
                                selected={get(values, 'flexPackage')}
                                error={get(errors, 'flexPackage.value', '')}
                                options={get(config, 'lists.flexPackage')}
                                onChange={value => {
                                    dispatchInitializr({
                                        type: 'UPDATE',
                                        payload: {flexPackage: value},
                                        config: get(dependencies, 'list'),
                                    })
                                    dispatch({
                                        type: 'UPDATE_DEPENDENCIES',
                                        payload: {flexPackage: value},
                                    })
                                }}
                            />
                            {get(errors, 'flexPackage') && (
                                <FieldError>
                                    Broadleaf Flex Package {get(errors, 'flexPackage.value')} is not
                                    supported.
                                    Please select a valid version.
                                </FieldError>
                            )}
                        </Control>

                        <Control text='Spring Boot' display='none'>
                            <Radio
                                name='boot'
                                selected={get(values, 'boot')}
                                error={get(errors, 'boot.value', '')}
                                options={get(config, 'lists.boot')}
                                onChange={value => {
                                    dispatchInitializr({
                                        type: 'UPDATE',
                                        payload: {boot: value},
                                        config: get(dependencies, 'list'),
                                    })
                                    dispatch({
                                        type: 'UPDATE_DEPENDENCIES',
                                        payload: {boot: value},
                                    })
                                }}
                            />
                            {get(errors, 'boot') && (
                                <FieldError>
                                    Spring Boot {get(errors, 'boot.value')} is not supported.
                                    Please select a valid version.
                                </FieldError>
                            )}
                        </Control>

                        <Control text='Project Metadata'>
                            <FieldInput
                                id='input-group'
                                value={get(values, 'meta.group')}
                                text='Group'
                                onChange={event => {
                                    const newValue = event.target.value
                                    update({meta: {group: newValue}})
                                    update({meta: {packageName: newValue}})
                                }}
                            />
                            <FieldInput
                                id='input-artifact'
                                value={get(values, 'meta.artifact')}
                                text='Artifact'
                                hidden={true}
                                onChange={event => {
                                    update({meta: {artifact: event.target.value}})
                                }}
                            />
                            <FieldInput
                                id='input-name'
                                value={get(values, 'meta.name')}
                                text='Name'
                                hidden={true}
                                onChange={event => {
                                    update({meta: {name: event.target.value}})
                                }}
                            />
                            <FieldInput
                                id='input-description'
                                value={get(values, 'meta.description')}
                                text='Description'
                                hidden={true}
                                onChange={event => {
                                    update({meta: {description: event.target.value}})
                                }}
                            />
                            <FieldInput
                                id='input-packageName'
                                value={get(values, 'meta.packageName')}
                                text='Package name'
                                onChange={event => {
                                    update({meta: {packageName: event.target.value}})
                                }}
                            />
                            <FieldRadio
                                id='input-packaging'
                                value={get(values, 'meta.packaging')}
                                text='Packaging'
                                hidden={true}
                                options={get(config, 'lists.meta.packaging')}
                                onChange={value => {
                                    update({meta: {packaging: value}})
                                }}
                            />
                            <FieldRadio
                                id='input-java'
                                value={get(values, 'meta.java')}
                                text='Java'
                                hidden={true}
                                options={get(config, 'lists.meta.java')}
                                onChange={value => {
                                    update({meta: {java: value}})
                                }}
                            />
                        </Control>

                        <Dependency refButton={refDependency}/>

                    </div>
                </div>
                <div className='right'>
                    <Control text='Which Broadleaf Services Would You Like to Customize?'
                             onPopupHelp={() => onPopupHelp("service-packages")}>
                        <Checkbox
                            name='extensions'
                            selected={get(values, 'extensions')}
                            options={get(config, 'lists.extensions')}
                            onChange={value => {
                                dispatchInitializr({
                                    type: 'UPDATE_EXTENSION',
                                    payload: {extension: value},
                                })
                            }}
                        />
                    </Control>
                    <Control text='Would you like to enable demo data?'>
                        <Checkbox
                            name='demoExtensions'
                            selected={get(values, 'demoExtensions')}
                            options={demoExtensionOptions}
                            onChange={value => {
                                dispatchInitializr({
                                    type: 'UPDATE_DEMO_EXTENSION',
                                    payload: {demoExtension: value},
                                })
                            }}
                        />
                    </Control>
                    <div id="terms-of-use">
                        By downloading, you agree to our &nbsp;
                        <a href="https://www.broadleafcommerce.com/privacy" target="_blank">privacy policy</a>
                        &nbsp; and <a href="https://www.broadleafcommerce.com/tou" target="_blank">terms of use</a>.
                    </div>
                </div>
            </div>
            <Actions>
                {generating ? (
                    <span
                        className='placeholder-button placeholder-button-submit placeholder-button-special'>
            Generating...
          </span>
                ) : (
                    <Button
                        id='generate-project'
                        variant='primary'
                        onClick={onSubmit}
                        hotkey={`${windowsUtils.symb} + ⏎`}
                        refButton={refSubmit}
                        disabled={generating}
                    >
                        Generate
                    </Button>
                )}
                <Button
                    id='explore-project'
                    onClick={onExplore}
                    hotkey='Ctrl + Space'
                    refButton={refExplore}
                >
                    Explore
                </Button>
                <Button id='share-project' onClick={onShare}>
                    Share...
                </Button>
                <Button
                    id='help-video'
                    onClick={() => onPopupHelp("help-video")}>
                    Help Video
                </Button>
            </Actions>
        </>
    )
}

Fields.propTypes = {
    generating: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onExplore: PropTypes.func.isRequired,
    onShare: PropTypes.func.isRequired,
    onPopupHelp: PropTypes.func.isRequired,
    refExplore: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.instanceOf(Element)}),
    ]).isRequired,
    refSubmit: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.instanceOf(Element)}),
    ]).isRequired,
    refDependency: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({current: PropTypes.instanceOf(Element)}),
    ]).isRequired,
}

export default Fields
