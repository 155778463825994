import PropTypes from 'prop-types'
import React from 'react'

function CheckboxInput({ handler, value, disabled, error, checked, text }) {
    const onClick = event => {
        event.preventDefault()
        handler(value)
    }
    if (disabled || error) {
        return (
            <span
                className={`checkbox disabled ${checked ? 'checked' : ''} ${
                    error ? 'err' : ''
                }`}
            >
        <span className='caret' tabIndex='-1' />
        <span className='checkbox-content' tabIndex='-1'>
          {text}
        </span>
      </span>
        )
    }
    return (
        <a
            href='/'
            className={`checkbox ${checked ? 'checked' : ''}`}
            onClick={onClick}
        >
            <span className='caret' tabIndex='-1' />
            <span className='checkbox-content' tabIndex='-1'>
        {text}
      </span>
        </a>
    )
}

CheckboxInput.defaultProps = {
    disabled: false,
    error: false,
}

CheckboxInput.propTypes = {
    checked: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
}

function Checkbox({ onChange, options, error, selected, disabled }) {
    const onChangeHandler = value => {
        if (onChange) {
            onChange(value)
        }
    }
    const allOptions = options.map(option => {
        return (
            <CheckboxInput
                key={option.key}
                checked={!error && selected.indexOf(option.key) > -1}
                text={option.text}
                value={option.key}
                disabled={disabled}
                handler={onChangeHandler}
            />
        )
    })
    if (error) {
        allOptions.push(
            <CheckboxInput
                key={allOptions.length + 1}
                checked
                text={error}
                value={error}
                disabled={disabled}
                handler={onChangeHandler}
                error
            />
        )
    }
    return <div className='group-checkbox' style={{columnCount : 2}}>{allOptions}</div>
}

Checkbox.defaultProps = {
    selected: [],
    error: null,
    onChange: null,
    disabled: false,
    options: {
        error: '',
    },
}

Checkbox.propTypes = {
    selected: PropTypes.array,
    error: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

export default Checkbox
