import PropTypes from 'prop-types'
import get from 'lodash.get'
import React, {useEffect, useRef} from 'react'
import {CSSTransition, TransitionGroup} from 'react-transition-group'
import {clearAllBodyScrollLocks, disableBodyScroll} from 'body-scroll-lock'
import popupMessages from "../../../messages/popupMessages.json";

function HelpPopover({open, onClose, popupType}) {
    const wrapper = useRef(null)
    const input = useRef(null)

    const flex_header = popupMessages.flex_popup.header_text
    const flex_content = popupMessages.flex_popup.content_text
    const service_header = popupMessages.service_popup.header_text
    const service_content = popupMessages.service_popup.content_text
    const help_header = popupMessages.help_popup.header_text
    const help_content = popupMessages.help_popup.content_text
    let headerText = "Placeholder"
    let contentText = "Placeholder"

    if (popupType === "flex-packages") {
        headerText = flex_header;
        contentText = flex_content;
    } else if (popupType === "service-packages") {
        headerText = service_header;
        contentText = service_content;
    } else if (popupType === "help-video") {
        headerText = help_header;
        contentText = help_content;
    }


    useEffect(() => {
        const clickOutside = event => {
            const children = get(wrapper, 'current')
            if (children && !children.contains(event.target)) {
                onClose()
            }
        }
        document.addEventListener('mousedown', clickOutside)
        if (get(input, 'current')) {
            get(input, 'current').focus()
        }
        return () => {
            document.removeEventListener('mousedown', clickOutside)
        }
    }, [onClose, input])

    useEffect(() => {
        if (get(wrapper, 'current') && open) {
            disableBodyScroll(get(wrapper, 'current'))
        }
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [wrapper, open])


    return (
        <>
            <TransitionGroup component={null}>
                {open && (
                    <CSSTransition classNames={'popup'} timeout={300}>
                        <div className='popup-share popup-service-help'>
                            <div className='popop-share-container popup-help-container'
                                 ref={wrapper}>
                                <div className='popup-header'>
                                    <h1>{headerText}</h1>
                                </div>
                                <div className='popup-content' dangerouslySetInnerHTML={{
                                    __html: contentText
                                }}>
                                </div>
                                <div className='popup-action'>
                                    <a
                                        href='/#'
                                        onClick={e => {
                                            e.preventDefault()
                                            onClose()
                                        }}
                                        className='button'
                                    >
                                <span className='button-content' tabIndex='-1'>
                                    <span>Close</span>
                                    <span className='secondary desktop-only'>ESC</span>
                                </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </CSSTransition>
                )}
            </TransitionGroup>
        </>
    )
}

HelpPopover.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    popupType: PropTypes.string.isRequired
}

export default HelpPopover