import PropTypes from 'prop-types'
import React from 'react'

const Control = ({text, children, labelFor, display, onPopupHelp}) => {
  if (onPopupHelp) {
    return (
        <div
            className='control'
            style={{display: display}}
        >
          <label className='label' htmlFor={labelFor}>
            {text} -&nbsp;
            {onPopupHelp && <a href="#"
                               className={"popupType-link"}
                               onClick={onPopupHelp}>What does this mean?</a>}
          </label>
          <div className='control-element'>{children}</div>
        </div>
    )
  } else {
    return (
        <div
            className='control'
            style={{display: display}}
        >
          <label className='label' htmlFor={labelFor}>
            {text}
          </label>
          <div className='control-element'>{children}</div>
        </div>
    )
  }
}

Control.defaultProps = {
  children: null,
  labelFor: '',
  display: 'block',
  onPopupHelp: null
}

Control.propTypes = {
  children: PropTypes.node,
  labelFor: PropTypes.string,
  text: PropTypes.string.isRequired,
  display: PropTypes.string,
  onPopupHelp: PropTypes.func
}

export default Control
