import React from 'react'

import HeaderMobile from './HeaderMobile'
import Logo from './Logo'

const Header = ({ menu = false }) => (
  <header id='header'>
    <div className='not-mobile'>
      <h1 className='logo'>
        <a href='/'>
          <span className='logo-content' tabIndex='-1'>
            <Logo menu={menu} />
          </span>
        </a>
      </h1>
      <div>
          Need Credentials? &nbsp;
          Request a <a href="https://www.broadleafcommerce.com/developer-evaluation" target="_blank">free developer trial evaluation</a> or <a href="https://www.broadleafcommerce.com/contact" target="_blank">reach out</a> to speak with one of our architects.
      </div>
    </div>
    <HeaderMobile menu={menu} />
  </header>
)

export default Header
